<template>
    <v-hover v-slot="{ hover }">
        <v-row no-gutters class="py-8"
            :style="(hover || currentlyInPlayer) ? { 'background-color': 'var(--v-primary-lighten5)' } : {}">
            <template v-if="!isExpiring">
                <v-col style="min-width: 232px">
                    <div class="d-flex align-center pl-4">
                        <v-btn v-if="(containAudio && hover) || currentlyInPlayer" fab small elevation="0"
                            color="primary lighten-4">
                            <v-icon color="primary" @click="togglePlay">
                                {{ currentlyPlaying ? 'mdi-pause' : 'mdi-play' }}
                            </v-icon>
                        </v-btn>
                        <v-btn v-else fab small elevation="0"
                            :color="(hover || currentlyInPlayer) ? 'primary lighten-4' : 'grey lighten-4'"
                            style="pointer-events: none">
                            <span class="text--primary">{{ answer.position }}</span>
                        </v-btn>
                        <div class="ml-4">
                            <respondent-info :answer="answer"></respondent-info>
                            <div v-if="containAudio" class="text-caption mt-1 grey--text">
                                {{ formattAnswerDate(answer.created_at, false) }}
                            </div>
                            <div v-else>
                                <v-chip label small class="pa-1">Text Only</v-chip>
                                <span class="text-caption mt-1 grey--text"> • {{ formattAnswerDate(answer.created_at,
                                    true) }}</span>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="text-body-2">
                        <span v-if="!enableTranscript">
                            {{ formattedTranscript }}

                            <a class="text-decoration-underline deep-purple--text text--accent-3" @click="openDialog"><v-icon color="deep-purple accent-3" class="mr-1" x-small>mdi-diamond</v-icon>{{formattedTranscript? 'read more': 'transcript'}}</a>
                        </span>
                        <v-tooltip v-else-if="['processing', 're-processing'].includes(answer.status)" bottom
                            :max-width="260">
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip label small color="primary lighten-2" v-bind="attrs"
                                    v-on="on">Processing</v-chip>
                            </template>
                            <span>Some responses require a little more time to process. Refresh this page or check back
                                soon 🙌</span>
                        </v-tooltip>
                        <span v-else-if="!answer.transcript" class="text-body-2 grey--text text--darken-1">--</span>
                        <template v-else>
                            <span  :class="['full-transcript-text', {'text-caption': longTranscript}]" v-html="transcriptRendered"></span>
                            <a @click="showOriginal=!showOriginal" v-if="hasTranslation" class="text-caption grey--text pt-1 d-block">{{ showOriginal? 'View Translation' : 'View Original' }}</a>
                        </template>
                    </div>
                </v-col>
                <v-col>
                    <div style="width: 70px; height: 100%;" class="d-flex justify-center">
                        <div class="mt-1">
                            <v-tooltip :color="!enableSentiment?'deep-purple accent-3':'secondary'" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <upgrade-wrapper v-if="!enableSentiment" v-bind="attrs" v-on="on" :blocked="!enableSentiment">
                                        <v-btn icon color="deep-purple accent-3">
                                            <v-icon>
                                                $sentiment
                                            </v-icon>
                                        </v-btn>
                                    </upgrade-wrapper>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'negative'" color="red">
                                        mdi-emoticon-frown-outline</v-icon>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'positive'"
                                        color="green">
                                        mdi-emoticon-outline</v-icon>
                                    <v-icon v-bind="attrs" v-on="on" v-else-if="answer.sentiment === 'neutral'">
                                        mdi-emoticon-neutral-outline</v-icon>
                                    <span class="text-body-2 grey--text text--darken-1" v-else>--</span>
                                </template>
                                <span v-if="!enableSentiment"><v-icon small color="white" left>mdi-diamond</v-icon>Upgrade</span>
                                <span v-else-if="answer.sentiment === 'neutral'">neutral</span>
                                <span v-else-if="answer.sentiment && answer.sentiment_score">
                                    {{ Math.round(Math.abs(answer.sentiment_score) * 100) + '% ' + answer.sentiment }}
                                </span>
                                <span v-else>N/A</span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div style="max-width: 150px; height: 100%" class="d-flex pr-4">
                        <!-- TODO: implement sharing -->
                        <v-btn icon v-show="hover && false">
                            <v-icon>mdi-share-variant</v-icon>
                        </v-btn>
                        <v-btn icon class="mx-6" @click="handleLike">
                            <v-icon v-if="liked" color="red">mdi-heart</v-icon>
                            <v-icon v-else>mdi-heart-outline</v-icon>
                        </v-btn>
                        <upgrade-wrapper :blocked="!enableAudioExport" v-show="hover">
                            <v-btn :color="!enableAudioExport? 'deep-purple accent-3': 'default'" icon >
                                <v-icon @click="downloadAudio">mdi-download</v-icon>
                            </v-btn>
                        </upgrade-wrapper>
                    </div>
                </v-col>
            </template>
            <!-- Expired column -->
            <template v-else>
                <v-col style="min-width: 232px">
                    <div class="d-flex align-center pl-4">
                        <v-btn disabled fab small elevation="0" color="grey lighten-4" style="pointer-events: none">
                            <span class="text--primary">{{ answer.position }}</span>
                        </v-btn>
                        <div class="ml-4">
                            <div class="text-caption mt-1 grey--text">
                                {{ formattAnswerDate(answer.created_at, false) }}
                            </div>
                            <v-chip :class="['mt-2', {'deep-orange--text text--darken-4': hover}]" small elevation="0" :color="hover?'deep-orange lighten-4' :'deep-orange lighten-1'" dark label>
                                <v-icon small left>
                                    mdi-lock-outline
                                </v-icon>
                                expires {{ $date(answer.expires_at).fromNow() }}
                            </v-chip>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="text-body-2">
                        <v-skeleton-loader
                            boilerplate
                            class="pr-4"
                            type="paragraph@1"
                        ></v-skeleton-loader>
                    </div>
                </v-col>
                <v-col>
                    <div style="width: 70px; height: 100%;" class="d-flex justify-center">
                        <div class="mt-1">
                            <span class="text-body-2 grey--text text--darken-1">--</span>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div style="max-width: 150px; height: 100%" class="d-flex pr-4">
                        <v-dialog width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" text elevation="0" color="primary" class="mx-6" >
                                    <v-icon left>mdi-lock-open-variant-outline</v-icon>
                                    Unlock
                                </v-btn>
                            </template>
                            <v-card class="pa-8">
                                <locked-response :expiresAt="answer.expires_at" :submissionCount="answer.submission_count_current_period"></locked-response>
                            </v-card>
                        </v-dialog>
                    </div>
                    
                </v-col>
            </template>
        </v-row>
    </v-hover>
</template>

<script>
import RespondentInfo from '../RespondentInfo.vue';
import dayjs from 'dayjs';
import { mapGetters, mapMutations } from 'vuex';
import { downloadAudioFile } from '../../../utils/processAudio';
import LockedResponse from '../../../views/dashboard/voiceform/results/LockedResponse.vue';
import { toggleLikeAnswer } from '../../../api/survey';
import UpgradeWrapper from '../../UpgradeWrapper.vue';

export default {
    components: { RespondentInfo, LockedResponse, UpgradeWrapper },
    data() {
        return {
            liked: this.answer.liked || false,
            showOriginal: false,
        };
    },
    watch: {
        'answer.liked'(val) {
            this.liked = val;
        }
    },
    props: {
        answer: Object
    },
    computed: {
        ...mapGetters({
            audioPlayer: 'survey/audioPlayer',
            user: 'auth/user',
            features: 'auth/features',
        }),
        currentlyPlaying() {
            return this.audioPlayer.isPlaying && this.audioPlayer.currentAudioAnswer?.id === this.answer.id;
        },
        currentlyInPlayer() {
            return this.audioPlayer.currentAudioAnswer?.id === this.answer.id;
        },
        containAudio() {
            return this.answer.file_url && typeof this.answer.file_url === 'string' && this.answer.file_url !== '';
        },
        longTranscript() {
            return this.answer.transcript && this.answer.transcript.length > 100;
        },
        selectedKeywords(){
            return this.$route && this.$route.query && this.$route.query.keywords ? this.$route.query.keywords.split(',') : []
        },
        hasTranslation(){
            return !!this.answer.translation
        },
        transcriptRendered() {
            let transcript = !this.showOriginal && this.answer.translation || this.answer.transcript;
            if (this.selectedKeywords && this.selectedKeywords.length !== 0 && this.answer?.keywords?.length !== 0) {
                const filteredKeywords = this.answer.keywords.filter(keyword => !!keyword.quote && this.selectedKeywords.includes(keyword.text.toLowerCase()));
                if (filteredKeywords.length > 0) {
                    // Building a map of quotes to keywords for tooltip
                    const keywordMap = filteredKeywords.reduce((acc, keyword) => {
                        acc[keyword.quote] = keyword.text; // Mapping quote to keyword
                        return acc;
                    }, {});

                    // Escape regex special characters
                    // eslint-disable-next-line no-useless-escape
                    const regexPattern = filteredKeywords.map(keyword => keyword.quote.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|');
                    const regex = new RegExp(`(${regexPattern})`, 'g');

                    // Replace matched text with mark and tooltip
                    transcript = transcript.replace(regex, (matched) => `<mark data-tooltip="${keywordMap[matched]}">${matched}</mark>`);
                }
            }
            return transcript;
        },
        formattedTranscript() {
            if(!this.enableTranscript){
                return this.answer.transcript ? this.answer.transcript.slice(0, 20)+'...': '';
            }
            return Array.from(this.answer.transcript).slice(0, 100).join('').trim() + '...';
        },
        showVisualization(){
            return this.user && this.user.has_visualization
        },
        isExpiring(){
            return !!(this.answer && this.answer.expires_at)
        },
        enableAudioExport(){
            return !!this.features && this.features.audio_export
        },
        enableTranscript(){
            return (!!this.features && this.features.transcription) || !this.containAudio
        },
        enableSentiment(){
            return !!this.features && this.features.audio_analytics
        },
    },
    methods: {
        ...mapMutations({
            'openDialog': 'openDialog',
            setAudioPlayerPlaying: 'survey/setAudioPlayerPlaying'
        }),
        formattAnswerDate(dateString, dateOnly = false) {
            return dayjs(dateString).format(dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mma');
        },
        downloadAudio() {
            if (this.answer.file_url && this.enableAudioExport) {
                downloadAudioFile(this.answer.file_url, this.answer.id);
            }
        },
        togglePlay() {
            if (this.currentlyPlaying) {
                this.setAudioPlayerPlaying(false);
            } else {
                this.$emit('playAudio', this.answer);
            }
        },
        async handleExportToVideo(){
            this.$store.state.visualization.answer = this.answer
            this.$store.state.visualization.showExportEditorDialog = true
        },
        handleLike() {
            try {
                this.liked = !this.liked
                toggleLikeAnswer(this.answer.id, !this.answer.liked)
                this.$emit('onLikeToggle', this.answer, !this.answer.liked);
            } catch (error) {
                console.error('handleLike',error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .full-transcript-text::v-deep mark {
        background-color: var(--v-yellow-lighten2);
        padding: 0 4px;                              
        border-radius: 4px;
        position: relative; /* Needed for positioning the tooltip */
        cursor: pointer; /* Optional: changes the cursor on hover */
    }

    .full-transcript-text::v-deep mark[data-tooltip]::after {
        content: attr(data-tooltip); /* Use the custom data attribute for tooltip text */
        position: absolute;
        bottom: 100%; /* Position above the mark */
        left: 50%; /* Center it horizontally */
        transform: translateX(-50%); /* Ensure it's centered */
        white-space: nowrap; /* Keeps the tooltip on one line */
        background-color: var(--v-secondary-base); /* Tooltip background */
        color: white; /* Tooltip text color */
        text-align: center;
        border-radius: 4px;
        padding: 4px 8px;
        margin-bottom: 4px;
        font-size: 12px;
        opacity: 0; /* Start fully transparent */
        visibility: hidden; /* Hide tooltip initially */
        z-index: 100; /* Ensure it's above other content */
        transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for opacity and visibility */
    }

    .full-transcript-text::v-deep mark[data-tooltip]:hover::after {
        opacity: 1; /* Fully visible on hover */
        visibility: visible; /* Make tooltip visible */
    }
</style>
